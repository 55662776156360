<template>
  <v-card class="pa-4">
    <div class="d-flex align-center">
      <h2>{{ $t('datevSetting') }}</h2>
      <v-spacer/>
      <v-btn
        v-if="!edit"
        class="mr-2"
        color="info"
        elevation="0"
        @click="edit=true"
      >
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        {{ $t('editSetting') }}
      </v-btn>
      <v-btn
        v-if="edit"
        class="primary"
        elevation="0"
        @click="saveConfirm"
      >
        <v-icon left>
          mdi-content-save-outline
        </v-icon>
        {{ $t('save') }}
      </v-btn>
      <v-btn
        v-if="!edit"
        :loading="loading"
        color="primary"
        elevation="0"
        @click="sendDatevToEmail"
      >
        <v-icon left>
          mdi-send
        </v-icon>
        {{ $t('manuallySend') }}
      </v-btn>
    </div>
    <v-card
      v-if="edit"
      class="mt-4"
      elevation="0"
    >
      <div>
        <v-tabs>
          <v-tab>{{ $t('basicSetting') }}</v-tab>
          <v-tab>{{ $t('accountSetting') }}</v-tab>

          <v-tab-item>
            <div class="pa-2">
              <template v-for="(item,index) in notKontoForms ">
                <div
                  :key="index"
                >
                  <div class="text-overline">
                    {{ item.fieldName }}
                  </div>
                  <v-text-field
                    v-model="item.value"
                    :placeholder="item.description"
                    :rules="item.isRequired === '1' ? [() => !!item.value || 'This field should not be empty'] : []"
                    dense
                    hide-details
                    outlined
                  />
                  <div
                    class="text--secondary py-1"
                    style="font-size: 12px"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="pa-2">
              <template v-for="(item,index) in kontoForms ">
                <div
                  :key="index"
                >
                  <div class="text-overline">
                    {{ item.fieldName }}
                  </div>
                  <v-text-field
                    v-model="item.value"
                    :placeholder="item.description"
                    :rules="item.isRequired === '1' ? [() => !!item.value || 'This field should not be empty'] : []"
                    dense
                    hide-details
                    outlined
                  />
                  <div
                    class="text--secondary py-1"
                    style="font-size: 12px"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-card>

    <v-card
      v-if="!edit"
      class=" mt-4"
      elevation="0"
    >
      <div class="mt-2">
        {{ $t('1datev') }}
      </div>
      <div class="mt-2">
        {{ $t('csvWillBeSendTo') }}<b> {{ emailAddress }}</b>
      </div>
      <v-divider class="mt-4"/>
      <div class="d-flex mt-4">
        <div>
          <div class="font-weight-bold">
            注意：最新只能选择上个月的datev下载
          </div>
          <v-date-picker
            v-model="picker"
            :max="maxMonth"
            color="primary"
            header-color="primary"
            locale="zh"
            type="month"
          />
        </div>
        <v-spacer/>
        <v-btn
          color="primary"
          elevation="0"
          @click="downDatevCsv"
        >
          <v-icon left>
            mdi-download
          </v-icon>
          下载Datev
        </v-btn>
      </div>
    </v-card>
  </v-card>
</template>

<script>

  import { sendDatevCSVtoReceiver, showAllDatevSettings, updateDatevSettings } from '@/common/Utlis/api'
  import Utils from '@/common/Utlis/Utils'
  import { showSuccessMessage } from '@/common/utils'
  import dayjs from 'dayjs'
  import GlobalConfig from '@/GlobalSettings'

  export default {
    name: 'Datev',
    data: function () {
      return ({
        maxMonth: null,
        downloadUrl: null,
        selectDate: null,
        picker: new Date().toISOString().substr(0, 7),
        datevForm: null,
        emailAddress: null,
        sendStatus: null,
        edit: false,
        loading: false,
      })
    },
    computed: {
      kontoForms () {
        return this.datevForm?.filter(it => it.fieldName.toLowerCase().includes('konto')) ?? []
      },
      notKontoForms () {
        console.log(this.datevForm, 'form')
        console.log(this.datevForm?.filter(it => !it.fieldName.toLowerCase().includes('konto')) ?? [])
        return this.datevForm?.filter(it => !it.fieldName.toLowerCase().includes('konto')) ?? []
      },
    },

    async mounted () {
      await this.initialize()
      await this.sendDatevToEmail()
      this.maxMonth = dayjs(this.picker).add(-1, 'month').startOf('month').format('YYYY-MM')
    },
    methods: {
      async downDatevCsv () {
        this.selectDate = dayjs(this.picker).add(1, 'month').add(4, 'hour').format('YYYY-MM-DD HH:mm:ss')
        window.open('http://' + GlobalConfig.Base + '/PHP/Datev.php?op=exportAndDownloadDatevCsv&now=' + this.selectDate, '_blank')
      },
      async sendDatevToEmail () {
        this.loading = true
        await sendDatevCSVtoReceiver()
        showSuccessMessage()
        this.loading = false
      },

      saveConfirm () {
        let hasEmptyItem = false

        if (this.datevForm.find(_ => _.isRequired === '1' && _.value === '')) {
          hasEmptyItem = true
          Utils.showError('some field is empty')
          return false
        }
        if (!hasEmptyItem) {
          console.log('hasEmptyItem', hasEmptyItem)
          updateDatevSettings(this.datevForm).then(() => {
            Utils.toast(this.$t('submit_success'))
            this.initialize()
          })
        }
      },

      async initialize () {
        this.edit = false
        this.datevForm = await showAllDatevSettings()
        console.log(this.datevForm)
        this.emailAddress = this.datevForm.find(_ => _.key === 'sendingToEmail').value
      },

    },
  }

</script>
